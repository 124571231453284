











import { defineComponent, computed, PropType } from '@vue/composition-api';
import { Branch, Deployment, Project } from '@/model/store';
import AppProjectDeployment from '@/components/project/deployment/Deployment.vue';

export default defineComponent({
  name: 'AppProjectDeploymentsTableData',
  components: { AppProjectDeployment },
  props: {
    project: {
      type: Object as PropType<Project>,
      required: true,
    },
    branches: {
      type: Array as PropType<Branch[]>,
      required: true,
    },
    forProductionDeployments: Boolean,
  },
  setup(props) {
    const sortedDeployments = computed<Deployment[]>(() => {
      let deployments: Deployment[] = [];
      Array.from(props.branches)
        .filter((branch: Branch) => branch.deploymentCount > 0)
        .sort((a: Branch, b: Branch) => {
          const lastBuildDateA = a.buildState?.lastBuildDate;
          const lastBuildDateB = b.buildState?.lastBuildDate;
          if (lastBuildDateA && lastBuildDateB) {
            return lastBuildDateA > lastBuildDateB ? -1 : 1;
          } else if (!lastBuildDateA && lastBuildDateB) {
            return 1;
          } else if (lastBuildDateA && !lastBuildDateB) {
            return -1;
          }
          return 0;
        })
        .forEach((branch: Branch) => {
          deployments = deployments.concat(branch.deployments);
        });
      return deployments;
    });

    return {
      sortedDeployments,
    };
  },
});
