


































import { defineComponent, PropType, ref, computed } from '@vue/composition-api';
import { Project } from '@/model/store';
import { patchProject, visitorStatisticsEnabledPatch } from '@/services/patch-service';

export default defineComponent({
  name: 'AppChangeVisitorStatisticsDialog',
  props: {
    project: {
      type: Object as PropType<Project>,
      required: true,
    },
  },
  setup(props) {
    const loading = ref(false);
    const visitorStatisticsEnabled = ref<boolean>(false);

    const dialog = {
      dialogRef: ref(null as any),
      open: () => {
        visitorStatisticsEnabled.value = props.project.visitorStatisticsEnabled ?? false;
        dialog.dialogRef.value.open();
      },
      close: () => {
        dialog.dialogRef.value.close();
      },
      formValid: computed<boolean>(() => {
        return visitorStatisticsEnabled.value !== props.project.visitorStatisticsEnabled;
      }),
      save: () => {
        loading.value = true;
        patchProject(props.project.id, visitorStatisticsEnabledPatch(visitorStatisticsEnabled.value))
          .then(() => {
            dialog.close();
          })
          .finally(() => {
            loading.value = false;
          });
      },
    };

    return {
      ...dialog,
      loading,
      visitorStatisticsEnabled,
    };
  },
});
