
































































































































import { defineComponent, PropType, ref, computed, watch } from '@vue/composition-api';
import { Project } from '@/model/store';
import { ACCOUNT_ME } from '@/utils/const';
import AppDomainSelect from '@/components/project/domain/DomainSelect.vue';
import AppDeploymentBranchSelect from '@/components/project/shared/DeploymentBranchSelect.vue';
import { useProjectsStore } from '@/stores/projects';
import { branchCounter } from '@/utils/projects';
import { useAccountInfoStore } from '@/stores/account-info';
import AppDeploymentNameInput from '@/components/project/shared/DeploymentNameInput.vue';
import { deploymentToStoreDeployment } from '@/model/storeApiConverter';
import { Deployment as ApiDeployment } from 'ionos-space-api-v4';
import { deploymentApiClient } from '@/plugins/axios';
import { ProjectType } from '@/model/store';
import AppDomainConflict from '@/components/project/domain/DomainConflict.vue';

export default defineComponent({
  name: 'AppAddDeploymentDialog',
  components: { AppDomainConflict, AppDeploymentNameInput, AppDeploymentBranchSelect, AppDomainSelect },
  props: {
    project: {
      type: Object as PropType<Project>,
      required: true,
    },
  },
  setup(props) {
    const projectsStore = useProjectsStore();
    const loading = ref(false);

    const name = ref<string>('');
    const nameProposal = ref<string>('');

    const selectedBranch = ref<string>('');
    const selectedBranchHasWorkflow = ref<boolean>(true);

    const selectedDomain = ref<string>('');
    const domainConflict = ref();

    const forProductionDeployment = ref<boolean>(false);

    const projectHasBranchesWithoutDeployment = computed<boolean>(() => {
      return branchCounter(props.project.branches, (branch) => branch.deploymentCount === 0) > 0;
    });
    const projectHasFreeStagings = computed<boolean>(() => {
      return props.project.stagingDeploymentCount < props.project.maxStagingDeploymentCount;
    });
    const projectHasFreePackages = computed<boolean>(() => {
      const accountInfoStore = useAccountInfoStore();
      return accountInfoStore.hasFreePackage(props.project.projectType);
    });
    const newDeploymentPossible = computed<boolean>(() => {
      if (forProductionDeployment.value) {
        return projectHasFreePackages.value;
      }
      return projectHasFreeStagings.value && projectHasBranchesWithoutDeployment.value;
    });

    const upgradeTariff = (projectTypeName: string) => {
      useAccountInfoStore().upgradeTariff(projectTypeName);
    };

    watch(selectedBranch, (newVal) => {
      if (!forProductionDeployment.value) {
        const branch = projectsStore.branchById(props.project.id, newVal);
        if (branch) {
          selectedBranchHasWorkflow.value = branch.workflowPresent;
          nameProposal.value = branch.name;
        }
      }
    });

    const dialog = {
      dialogRef: ref(null as any),
      open: (_forProductionDeployment: boolean) => {
        name.value = '';
        nameProposal.value = '';
        selectedDomain.value = '';
        selectedBranch.value = '';
        domainConflict.value = undefined;
        selectedBranchHasWorkflow.value = true;
        forProductionDeployment.value = _forProductionDeployment;
        if (_forProductionDeployment && props.project.productionBranch) {
          selectedBranch.value = props.project.productionBranchId;
        }
        dialog.dialogRef.value.open();
      },
      close: () => {
        dialog.dialogRef.value.close();
      },
      formValid: computed<boolean>(() => {
        if (newDeploymentPossible.value) {
          if (forProductionDeployment.value) {
            return !!name.value;
          } else {
            return !!name.value && !!selectedBranch.value && selectedBranchHasWorkflow.value;
          }
        }
        return false;
      }),
      save: (_, force: boolean = false) => {
        loading.value = true;
        deploymentApiClient
          .createDeployment(ACCOUNT_ME, props.project.id, selectedBranch.value, {
            name: name.value,
            domain: selectedDomain.value
              ? {
                  domain: selectedDomain.value,
                  force: force,
                }
              : undefined,
          })
          .then(async ({ data }: { data: ApiDeployment }) => {
            const deployment = deploymentToStoreDeployment(props.project.id, selectedBranch.value, data);
            await projectsStore.loadProject(props.project.id);
            await projectsStore.loadBranch(props.project.id, selectedBranch.value);
            if (forProductionDeployment.value) {
              await projectsStore.addDeployment(props.project.id, selectedBranch.value, deployment.id, deployment);
              await useAccountInfoStore().reloadAccountInfo();
            } else {
              const branch = projectsStore.branchById(props.project.id, selectedBranch.value);
              if (branch) {
                branch._deploymentsLoaded = true;
                branch.deployments = [deployment];
                await projectsStore.replaceBranch(props.project.id, selectedBranch.value, branch);
              }
            }
            dialog.close();
          })
          .catch(async (error) => {
            if (error.response?.status === 409) {
              if (error.response?.data.conflictingRecords) {
                domainConflict.value = error.response?.data;
              } else if (error.response?.data.message === 'Max deployments reached') {
                await useAccountInfoStore().reloadAccountInfo();
              }
            }
          })
          .finally(() => {
            loading.value = false;
          });
      },
    };

    return {
      ...dialog,
      loading,
      name,
      nameProposal,
      selectedDomain,
      domainConflict,
      selectedBranch,
      forProductionDeployment,
      newDeploymentPossible,
      projectHasBranchesWithoutDeployment,
      projectHasFreeStagings,
      projectHasFreePackages,
      selectedBranchHasWorkflow,
      ProjectType,
      upgradeTariff,
    };
  },
});
