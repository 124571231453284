































import { computed, defineComponent, PropType, ref } from '@vue/composition-api';
import { Branch, Project } from '@/model/store';
import AppProjectSettingsMenuItem from '@/components/project/shared/SettingsMenuItem.vue';
import { isPhpProject as _isPhpProject } from '@/utils/projects';
import { BranchDeploymentStateType } from 'ionos-space-api-v4';
import { branchApiClient } from '@/plugins/axios';
import { ACCOUNT_ME } from '@/utils/const';

export default defineComponent({
  name: 'AppDeploymentsSettingsMenu',
  components: {
    AppProjectSettingsMenuItem,
  },
  props: {
    project: {
      type: Object as PropType<Project>,
      required: true,
    },
    forProductionDeployments: Boolean,
  },
  inject: ['openAddDeploymentDialog', 'openChangePhpVersionDialog'],
  setup(props) {
    const closeMenuOnContentClick = ref<boolean>(true);

    const isPhpProject = computed<boolean>(() => {
      return _isPhpProject(props.project);
    });

    const rerunFailedDeployments = () => {
      const branches: Branch[] = [];
      if (props.forProductionDeployments && props.project.productionBranch) {
        branches.push(props.project.productionBranch);
      } else {
        branches.push(...props.project.branches);
      }
      const calls = branches
        .filter((branch) => {
          return (
            branch.deploymentCount >= 1 &&
            (branch.deploymentState?.state === BranchDeploymentStateType.FAILED ||
              branch.deploymentState?.state === BranchDeploymentStateType.PARTLY_FAILED)
          );
        })
        .map((branch) => {
          return branchApiClient.triggerDeployments(ACCOUNT_ME, props.project.id, branch.id, {
            onlyFailed: true,
          });
        });
      return Promise.all(calls);
    };

    return {
      isPhpProject,
      rerunFailedDeployments,
      closeMenuOnContentClick,
    };
  },
});
