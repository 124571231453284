









import { defineComponent } from '@vue/composition-api';
import { useGitHubStore } from '@/stores/github';
import AppNotificationStatusPanel from '@/components/shared/NotificationStatusPanel.vue';

export default defineComponent({
  name: 'AppGithubInstallationStatusPanel',
  components: { AppNotificationStatusPanel },
  setup() {
    const githubStore = useGitHubStore();

    return {
      githubStore,
    };
  },
});
