









import { Branch } from '@/model/store';
import { defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  name: 'AppBranchBuildBranch',
  props: {
    branch: Object as PropType<Branch>,
  },
  setup() {},
});
