var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-cog-outline")])],1)]}}])},[_c('v-list',[_c('app-project-settings-menu-item',{attrs:{"title":_vm.$t('projectDetails.infoSection.projectSettingsMenu.projectPackage'),"value":_vm.$t('application.projectTypeLabels.' + _vm.project.projectType),"callback":_vm.isStarterProjectByType(_vm.project.projectType) ? _vm.openChangeProjectTypeDialog : null}}),_c('app-project-settings-menu-item',{attrs:{"title":_vm.$t('projectDetails.infoSection.projectSettingsMenu.name'),"value":_vm.project.name,"callback":_vm.openChangeNameDialog}}),(!_vm.isAMultiDeploymentProject && _vm.productionDeployment && _vm.productionDeployment.domain)?_c('app-project-settings-menu-item',{attrs:{"title":_vm.$t('projectDetails.infoSection.projectSettingsMenu.domain'),"value":_vm.productionDeployment.domain.name,"callback":function () { return _vm.openConnectDomainDialog(_vm.productionDeployment); }}}):_vm._e(),_c('v-divider'),(_vm.isPhpProject)?_c('app-project-settings-menu-item',{attrs:{"title":_vm.$t('projectDetails.infoSection.projectSettingsMenu.phpVersion'),"value":_vm.project.defaultPhpVersion,"callback":_vm.openChangePhpVersionDialog}}):_vm._e(),(_vm.isPhpProject)?_c('app-project-settings-menu-item',{attrs:{"title":_vm.$t('projectDetails.infoSection.projectSettingsMenu.publicFolderPath'),"value":_vm.project.defaultDomainRootPath,"callback":_vm.openChangePublicFolderPathDialog}}):_vm._e(),(!_vm.isAMultiDeploymentProject)?_c('app-project-settings-menu-item',{attrs:{"title":_vm.$t('projectDetails.infoSection.projectSettingsMenu.visitorStatistics'),"value":_vm.project.visitorStatisticsEnabled
          ? _vm.$t('application.project.settingsStandardValues.on')
          : _vm.$t('application.project.settingsStandardValues.off'),"callback":_vm.openChangeVisitorStatisticsDialog}}):_vm._e(),_c('app-project-settings-menu-item',{attrs:{"title":_vm.$t('projectDetails.infoSection.projectSettingsMenu.automaticStagingDeployments'),"value":!!_vm.project.automaticDeploymentEnabled
          ? _vm.$t('application.project.settingsStandardValues.on')
          : _vm.$t('application.project.settingsStandardValues.off'),"callback":_vm.openChangeAutomaticDeploymentDialog}}),_c('v-divider'),_c('app-project-settings-menu-item',{attrs:{"title":_vm.$t('projectDetails.actions.deleteProject'),"left-icon":"mdi-trash-can-outline","callback":_vm.openDeleteDialog}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }