
















































































import { computed, defineComponent, PropType, ref } from '@vue/composition-api';
import { Branch, BranchState, Deployment, Project } from '@/model/store';
import { isPhpProject as _isPhpProject } from '@/utils/projects';
import { urlWithoutProtocol } from '@/utils';
import { useProjectsStore } from '@/stores/projects';
import { storeToRefs } from 'pinia';
import AppDeploymentSettingsMenuItem from '@/components/project/shared/DeploymentSettingsMenuItem.vue';
import { BranchDeploymentStateType } from 'ionos-space-api-v4';

export default defineComponent({
  name: 'AppDeploymentSettingsMenu',
  components: { AppDeploymentSettingsMenuItem },
  props: {
    deployment: {
      type: Object as PropType<Deployment>,
      required: true,
    },
    domain: String,
  },
  inject: [
    'openChangePhpVersionDialog',
    'openChangePublicFolderPathDialog',
    'openWorkflowConfigurationDialog',
    'openChangeNameDialog',
    'openDeleteDialog',
    'openConnectDomainDialog',
  ],
  setup(props, { root }) {
    const projectsStore = useProjectsStore();

    const closeMenuOnContentClick = ref(true);
    const loadingPhpMyAdmin = ref(false);

    const project = computed<Project>(() => projectsStore.selectedProject);
    const branch = computed<Branch>(() =>
      projectsStore.branchById(props.deployment?._projectId, props.deployment?._branchId)
    );

    const { deploymentViewerLink, phpMyAdminToken, projectGithubUrl, projectBuildLogUrl } = storeToRefs(projectsStore);

    const isProductionBranch = computed<boolean>(() => {
      return project.value?.productionBranchId === branch.value?.id;
    });

    const viewStatisticsUrl = computed<string>(() => {
      return (
        root.$t('projectDetails.actions.visitorStatistics.url') +
        '/?domain=' +
        urlWithoutProtocol(!props.domain ? props.deployment?.webspace?.webspace.siteUrl ?? '' : props.domain)
      );
    });

    const isPhpProject = computed<boolean>(() => {
      return _isPhpProject(project.value);
    });

    async function openPhpMyAdminUrl() {
      try {
        loadingPhpMyAdmin.value = true;
        closeMenuOnContentClick.value = false;
        const projectId = props.deployment._projectId;
        const branchId = props.deployment._branchId;
        const deploymentId = props.deployment.id;
        let token = phpMyAdminToken.value(projectId, branchId, deploymentId);
        if (!token) {
          await projectsStore.refreshPhpMyAdminToken({
            projectId,
            branchId,
            deploymentId,
          });
          token = phpMyAdminToken.value(projectId, branchId, deploymentId);
        }
        window.open(token.token, '_blank');
      } finally {
        loadingPhpMyAdmin.value = false;
        closeMenuOnContentClick.value = true;
      }
    }

    const isBuildOrDeploymentRunning = computed<boolean>(() => {
      return (
        branch.value.buildState?.state === BranchState.RUNNING ||
        !branch.value.deploymentState ||
        branch.value.deploymentState?.state === BranchDeploymentStateType.RUNNING
      );
    });

    return {
      project,
      branch,
      deploymentViewerLink,
      phpMyAdminToken,
      projectGithubUrl,
      projectBuildLogUrl,
      closeMenuOnContentClick,
      loadingPhpMyAdmin,
      isPhpProject,
      isProductionBranch,
      viewStatisticsUrl,
      openPhpMyAdminUrl,
      isBuildOrDeploymentRunning,
    };
  },
});
