













































import { computed, defineComponent, PropType, ref } from '@vue/composition-api';
import { Deployment, Project } from '@/model/store';
import { useMasterDataStore } from '@/stores/master-data';

import axios from 'axios';
import {
  defaultPhpVersionPatch,
  deploymentPhpVersionPatch,
  patchDeployment,
  patchProject,
} from '@/services/patch-service';
import { useProjectsStore } from '@/stores/projects';
import { isAMultiDeploymentProject as _isAMultiDeploymentProject } from '@/utils/projects';

enum UpdateMode {
  default = 'default',
  singleDeployment = 'singleDeployment',
}

export default defineComponent({
  name: 'AppChangePhpVersionDialog',
  props: {
    project: {
      type: Object as PropType<Project>,
      required: true,
    },
  },
  setup(props) {
    const masterDataStore = useMasterDataStore();
    const projectsStore = useProjectsStore();

    const availableRuntimeVersions = computed<string[]>(() => masterDataStore.phpVersions);
    const isAMultiDeploymentProject = computed<boolean>(() => {
      return _isAMultiDeploymentProject(props.project);
    });

    const loading = ref(false);
    const selectedRuntimeVersion = ref<string | undefined>(props.project.defaultPhpVersion);
    const oldRuntimeVersion = ref<string | undefined>(props.project.defaultPhpVersion);
    const mode = ref<UpdateMode>(UpdateMode.default);
    const adaptForAllDeployments = ref(false);
    const deployment = ref<Deployment>(undefined as any);

    const dialog = {
      dialogRef: ref(null as any),
      open: (_deployment?: Deployment) => {
        adaptForAllDeployments.value = false;
        if (_deployment) {
          if (_deployment.webspace?.webspace.phpVersion) {
            selectedRuntimeVersion.value = oldRuntimeVersion.value = _deployment.webspace?.webspace.phpVersion;
            mode.value = UpdateMode.singleDeployment;
            deployment.value = _deployment;
          }
        } else {
          mode.value = UpdateMode.default;
          selectedRuntimeVersion.value = oldRuntimeVersion.value = props.project.defaultPhpVersion;
          deployment.value = undefined as any;
        }
        dialog.dialogRef.value.open();
      },
      close: () => {
        dialog.dialogRef.value.close();
      },
      save: async () => {
        if (!selectedRuntimeVersion.value) return;
        loading.value = true;
        try {
          const deployments: Deployment[] = [];
          if (mode.value === UpdateMode.default) {
            await patchProject(props.project.id, defaultPhpVersionPatch(selectedRuntimeVersion.value));
            if (adaptForAllDeployments.value) {
              const allDeploymentsOfProject = projectsStore.allDeploymentsOfProject(props.project.id);
              if (allDeploymentsOfProject) {
                deployments.push(allDeploymentsOfProject);
              }
            }
          } else {
            deployments.push(deployment.value);
          }
          if (deployments.length) {
            const deploymentRequests = deployments.map((deployment: Deployment) => {
              return patchDeployment(
                props.project.id,
                deployment._branchId,
                deployment.id,
                deploymentPhpVersionPatch(selectedRuntimeVersion.value as string)
              );
            });
            await axios.all(deploymentRequests);
          }
          dialog.dialogRef.value.close();
        } finally {
          loading.value = false;
        }
      },
    };

    return {
      UpdateMode,
      mode,
      ...dialog,
      loading,
      adaptForAllDeployments,
      selectedRuntimeVersion,
      oldRuntimeVersion,
      availableRuntimeVersions,
      isAMultiDeploymentProject,
    };
  },
});
