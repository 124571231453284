






import { defineComponent, ref, onMounted, watch } from '@vue/composition-api';
import { render } from 'timeago.js';

export default defineComponent({
  name: 'AppTimeAgo',
  props: {
    datetime: [String, Object] as any,
    small: Boolean,
  },
  setup(props) {
    const timeAgoRef = ref();

    onMounted(() => {
      render(timeAgoRef.value as HTMLElement);
    });

    watch(
      () => props.datetime,
      () => {
        setTimeout(() => render(timeAgoRef.value as HTMLElement), 500);
      }
    );

    return {
      timeAgoRef,
    };
  },
});
