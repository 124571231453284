































import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  name: 'AppProjectSettingsMenuItem',
  props: {
    title: String,
    value: {
      type: String,
      default: null,
    },
    leftIcon: {
      type: String,
      default: null,
    },
    rightIcon: {
      type: String,
      default: null,
    },
    callback: {
      type: Function,
      default: null,
    },
    externalLink: {
      type: String,
      default: null,
    },
    internalLink: {
      type: Object,
      default: null,
    },
    loading: Boolean,
    disabled: Boolean,
  },
  setup(props) {
    const actionIcon = computed<string | null>(() => {
      if (props.rightIcon) {
        return props.rightIcon;
      }
      if (props.callback && props.value) {
        return 'mdi-pencil-outline';
      }
      if (props.internalLink || props.externalLink) {
        return 'mdi-open-in-new';
      }
      return null;
    });

    return {
      actionIcon,
    };
  },
});
