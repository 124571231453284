






















































import { computed, defineComponent, ref } from '@vue/composition-api';
import AppDomainSelect from '@/components/project/domain/DomainSelect.vue';
import { useI18n } from 'vue-i18n-bridge';
import { Deployment } from '@/model/store';
import { ACCOUNT_ME } from '@/utils/const';
import { urlWithoutProtocol } from '@/utils';
import { deploymentApiClient } from '@/plugins/axios';
import AppDomainConflict from '@/components/project/domain/DomainConflict.vue';

export default defineComponent({
  name: 'AppConnectDomainDialog',
  components: { AppDomainConflict, AppDomainSelect },
  setup() {
    const { t } = useI18n();
    const loading = ref<string>('');
    const selectedDomain = ref<string>(undefined as any);
    const currentDomain = ref<string>(undefined as any);
    const domainField = ref(undefined as any);
    const conflict = ref(undefined as any);

    const deployment = ref<Deployment>(undefined as any);
    const defaultDomain = ref<string>(undefined as any);

    const hasDomainChanged = computed<boolean>(() => {
      return selectedDomain.value !== currentDomain.value;
    });
    const isDefaultDomainSelected = computed<boolean>(() => {
      return selectedDomain.value === defaultDomain.value;
    });

    const dialog = {
      dialogRef: ref(null as any),
      primaryAction: computed(() => {
        return {
          action: () => {
            loading.value = 'primary';
            conflict.value ? dialog.reset() : connectDomain();
          },
          label: conflict.value
            ? t('projectDetails.domains.connect.dialog.selectAnother')
            : t('projectDetails.domains.connect.dialog.select'),
        };
      }),
      secondaryAction: computed(() => {
        return {
          action: () => {
            loading.value = 'secondary';
            conflict.value ? connectDomain(true) : dialog.close();
          },
          label: conflict.value
            ? t('projectDetails.domains.connect.dialog.forceConnect')
            : t('application.button.cancel'),
        };
      }),
      open: (_deployment: Deployment) => {
        deployment.value = _deployment;
        currentDomain.value = _deployment.domain?.name ?? '';
        defaultDomain.value = urlWithoutProtocol(_deployment.webspace?.webspace.siteUrl ?? '');
        dialog.reset();
        dialog.dialogRef.value.open();
      },
      close: () => {
        dialog.dialogRef.value.close();
      },
      reset: () => {
        loading.value = '';
        selectedDomain.value = currentDomain.value;
        conflict.value = undefined;
      },
    };

    const connectDomain = (force?: boolean) => {
      if (isDefaultDomainSelected.value) {
        disconnectDomain();
        return;
      }
      deploymentApiClient
        .connectDomain(ACCOUNT_ME, deployment.value._projectId, deployment.value._branchId, deployment.value.id, {
          domain: selectedDomain.value,
          force: force ?? false,
        })
        .then(() => {
          dialog.close();
        })
        .catch((error) => {
          if (error.response?.status === 409) {
            conflict.value = error.response?.data;
          }
        })
        .finally(() => {
          loading.value = '';
        });
    };

    const disconnectDomain = () => {
      deploymentApiClient
        .disconnectDomain(ACCOUNT_ME, deployment.value._projectId, deployment.value._branchId, deployment.value.id)
        .then(() => {
          dialog.close();
        })
        .finally(() => {
          loading.value = '';
        });
    };

    return {
      ...dialog,
      loading,
      defaultDomain,
      selectedDomain,
      currentDomain,
      domainField,
      hasDomainChanged,
      conflict,
    };
  },
});
