import { render, staticRenderFns } from "./ChangeVisitorStatisticsDialog.vue?vue&type=template&id=2b0bcebc"
import script from "./ChangeVisitorStatisticsDialog.vue?vue&type=script&lang=ts"
export * from "./ChangeVisitorStatisticsDialog.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
installComponents(component, {VCardText})
