











































import { defineComponent, ref, computed, PropType } from '@vue/composition-api';
import { Deployment, Project } from '@/model/store';
import { deploymentApiClient } from '@/plugins/axios';
import { ACCOUNT_ME, DASHBOARD } from '@/utils/const';
import { useProjectsStore } from '@/stores/projects';
import { useAccountInfoStore } from '@/stores/account-info';

enum Mode {
  DeleteProjectDialog = 'DeleteProjectDialog',
  DeleteDeploymentDialog = 'DeleteDeploymentDialog',
}

export default defineComponent({
  name: 'AppDeleteDialog',
  props: {
    project: {
      type: Object as PropType<Project>,
      required: true,
    },
  },
  setup(props, { root }) {
    const loading = ref(false);
    const router = root.$router;

    const mode = ref<Mode>(Mode.DeleteProjectDialog);

    const deployment = ref<Deployment>(undefined as any);

    const name = ref<string>('');
    const confirmedName = ref<string>('');
    const deleteRepository = ref<boolean>(false);

    const dialog = {
      dialogRef: ref(null as any),
      open: (_deployment?: Deployment) => {
        if (_deployment) {
          mode.value = Mode.DeleteDeploymentDialog;
          deployment.value = _deployment;
          name.value = _deployment.name;
        } else {
          mode.value = Mode.DeleteProjectDialog;
          deployment.value = undefined as any;
          name.value = props.project.gitRepository.fullName;
        }
        confirmedName.value = '';
        deleteRepository.value = false;
        dialog.dialogRef.value.open();
      },
      close: () => {
        dialog.dialogRef.value.close();
      },
      formValid: computed<boolean>(() => {
        return name.value === confirmedName.value;
      }),
      save: () => {
        loading.value = true;
        let call: Promise<any> | undefined;
        if (mode.value === Mode.DeleteProjectDialog) {
          const projectsStore = useProjectsStore();
          call = projectsStore.deleteProject(props.project.id, deleteRepository.value).then(() => {
            router.push({ name: DASHBOARD });
          });
        } else {
          call = deploymentApiClient
            .deleteDeployment(ACCOUNT_ME, deployment.value._projectId, deployment.value._branchId, deployment.value.id)
            .then(async () => {
              await useProjectsStore().removeDeployment(
                deployment.value._projectId,
                deployment.value._branchId,
                deployment.value.id
              );
              if (deployment.value._branchId === props.project.productionBranchId) {
                await useAccountInfoStore().reloadAccountInfo();
              }
            });
        }
        if (call) {
          call
            .then(() => {
              dialog.close();
            })
            .finally(() => {
              loading.value = false;
            });
        }
      },
    };

    return {
      ...dialog,
      mode,
      Mode,
      loading,
      deployment,
      name,
      confirmedName,
      deleteRepository,
    };
  },
});
