











import { urlWithoutProtocol, urlWithHttpsProtocol } from '@/utils';
import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  name: 'AppDeploymentBuildDomain',
  props: {
    domain: String,
  },
  setup(props) {
    const end = '.app-ionos.space';

    const label = computed<string>(() => {
      const domain = urlWithoutProtocol(props.domain ?? '');
      if (domain.endsWith(end)) {
        return domain.substring(0, end.length - 1);
      }
      return domain;
    });
    const subLabel = computed<string>(() => {
      const domain = urlWithoutProtocol(props.domain ?? '');
      if (domain.endsWith(end)) {
        return domain.substring(end.length - 1);
      }
      return '';
    });

    return {
      label,
      subLabel,
      urlWithHttpsProtocol,
    };
  },
});
