
























































import { defineComponent, PropType, ref, computed } from '@vue/composition-api';
import { Project, ProjectType } from '@/model/store';
import { patchProject, projectTypePatch } from '@/services/patch-service';
import { useMasterDataStore } from '@/stores/master-data';
import { useAccountInfoStore } from '@/stores/account-info';
import AppProjectTypeFeatures from '@/components/shared/projectType/Features.vue';
import AppProjectTypeHeader from '@/components/shared/projectType/Header.vue';
import { isStarterProjectByType } from '@/utils/projects';
import { useI18n } from 'vue-i18n-bridge';

export default defineComponent({
  name: 'AppChangeProjectTypeDialog',
  components: { AppProjectTypeHeader, AppProjectTypeFeatures },
  props: {
    project: {
      type: Object as PropType<Project>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const masterDataStore = useMasterDataStore();
    const accountInfoStore = useAccountInfoStore();

    const allowedTypes = masterDataStore.getPackageWithRequiredFeatures([]).filter((x) => x.name !== ProjectType.php);

    const loading = ref(false);
    const selectedProjectTypeIndex = ref<number>(0);

    const mapPanelIndexToProjectType = (panelIndex: number) => {
      return allowedTypes[panelIndex];
    };

    const mapProjectTypeNameToPanelIndex = (projectTypeName: string) => {
      return allowedTypes.findIndex((value) => value.name === projectTypeName);
    };

    const projectTypeSwitchNotPossibleErrorMessage: (projectTypeName: string) => string | undefined = (
      projectTypeName
    ) => {
      if (projectTypeName === props.project.projectType) {
        return t('projectDetails.changeProjectTypeDialog.errorMessage.actualPackage');
      }
      if (!(projectTypeName === ProjectType.l && props.project.projectType === ProjectType.s)) {
        return t('projectDetails.changeProjectTypeDialog.errorMessage.switchToThisTypeNotPossible', {
          from: t(`application.projectTypeLabels.${props.project.projectType}`),
          to: t(`application.projectTypeLabels.${projectTypeName}`),
        });
      }
      if (!accountInfoStore.hasFreePackage(projectTypeName, props.project.productionDeploymentCount)) {
        return t('projectDetails.changeProjectTypeDialog.errorMessage.notEnoughFreePackages', {
          to: t(`application.projectTypeLabels.${projectTypeName}`),
        });
      }
      return undefined;
    };

    const upgradeTariff = (name: string) => {
      accountInfoStore.upgradeTariff(name);
    };

    const dialog = {
      dialogRef: ref(undefined as any),
      open: () => {
        selectedProjectTypeIndex.value = mapProjectTypeNameToPanelIndex(props.project.projectType);
        dialog.dialogRef.value.open();
      },
      close: () => {
        dialog.dialogRef.value.close();
      },
      formValid: computed<boolean>(() => {
        const selectedProjectType = mapPanelIndexToProjectType(selectedProjectTypeIndex.value);
        return !projectTypeSwitchNotPossibleErrorMessage(selectedProjectType.name);
      }),
      save: () => {
        loading.value = true;
        patchProject(
          props.project.id,
          projectTypePatch(mapPanelIndexToProjectType(selectedProjectTypeIndex.value).name)
        )
          .then(() => {
            dialog.close();
          })
          .finally(() => {
            loading.value = false;
          });
      },
    };

    return {
      ...dialog,
      loading,
      selectedProjectTypeIndex,
      allowedTypes,
      accountInfoStore,
      projectTypeSwitchNotPossibleErrorMessage,
      upgradeTariff,
      isStarterProjectByType,
    };
  },
});
