









































import { defineComponent, computed, PropType } from '@vue/composition-api';
import { Branch, Deployment } from '@/model/store';
import AppBranchBuildState from '@/components/shared/BranchBuildState.vue';
import AppBranchBuildBranch from '@/components/shared/BranchBuildBranch.vue';
import AppDeploymentDeploymentState from '@/components/shared/DeploymentDeploymentState.vue';
import AppDeploymentBuildDomain from '@/components/shared/DeploymentBuildDomain.vue';
import AppDeploymentSettingsMenu from '@/components/project/deployment/deploymentSettings/Menu.vue';
import { prettyBytes } from '@/utils';
import { useProjectsStore } from '@/stores/projects';

export default defineComponent({
  name: 'AppProjectDeployment',
  components: {
    AppDeploymentSettingsMenu,
    AppDeploymentBuildDomain,
    AppDeploymentDeploymentState,
    AppBranchBuildBranch,
    AppBranchBuildState,
  },
  props: {
    deployment: {
      type: Object as PropType<Deployment>,
      required: true,
    },
  },
  setup(props) {
    const branch = computed<Branch | undefined>(() => {
      const projectsStore = useProjectsStore();
      return projectsStore.branchById(props.deployment?._projectId, props.deployment?._branchId);
    });
    const multiDeployment = computed<boolean | undefined>(() => {
      return branch.value && (!branch.value.productionBranch || branch.value.deploymentCount === 1);
    });
    const domain = computed<string | undefined>(() => {
      return props.deployment?.domain?.name;
    });
    const storageQuota = computed<string>(() => {
      return prettyBytes(props.deployment?.webspace?.webspace.quota?.storageQuota);
    });
    const storageUsage = computed<string>(() => {
      return prettyBytes(props.deployment?.webspace?.webspace.quota?.storageUsage);
    });
    const percentageOfUsedStorage = computed<number>(() => {
      const storageQuota = props.deployment?.webspace?.webspace.quota?.storageQuota;
      const storageUsage = props.deployment?.webspace?.webspace.quota?.storageUsage;
      if (storageQuota && storageUsage && storageQuota > 0) {
        return Math.ceil((storageUsage / storageQuota) * 100);
      }
      return 0;
    });
    const progressBarClass = computed<object>(() => {
      return {
        empty: percentageOfUsedStorage.value === 0,
      };
    });

    return {
      branch,
      multiDeployment,
      domain,
      storageQuota,
      storageUsage,
      percentageOfUsedStorage,
      progressBarClass,
    };
  },
});
