











import { defineComponent } from '@vue/composition-api';
import AppProjectSettingsMenuItem from '@/components/project/shared/SettingsMenuItem.vue';

export default defineComponent({
  name: 'AppDeploymentSettingsMenuItem',
  components: { AppProjectSettingsMenuItem },
  props: {
    locked: Boolean,
    lockedIcon: {
      type: String,
      default: 'autorenew',
    },
    lockedText: String,
  },
});
