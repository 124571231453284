

































import { computed, defineComponent, PropType, ref } from '@vue/composition-api';
import { Project } from '@/model/store';
import { EnvVars } from '@/utils/env-vars';
import { BranchDeploymentStateType } from 'ionos-space-api-v4';
import { urlWithHttpsProtocol } from '@/utils';

const screenshotApiUrl = EnvVars.getScreenshotApiUrl() + '/v2/crawling/screenshot';

export default defineComponent({
  name: 'AppScreenshotImage',
  props: {
    project: {
      type: Object as PropType<Project>,
      required: true,
    },
  },
  setup(props) {
    const loading = ref<boolean>(false);

    const showPlaceholder = computed<boolean>(() => {
      if (!screenshotUrl.value) {
        return true;
      }
      switch (props.project.productionBranch?.deploymentState?.state) {
        case BranchDeploymentStateType.SUCCESS:
          return false;
      }
      return true;
    });
    const placeholderIcon = computed<string>(() => {
      switch (props.project.productionBranch?.deploymentState?.state) {
        case BranchDeploymentStateType.FAILED:
        case BranchDeploymentStateType.PARTLY_FAILED:
          return 'mdi-image-outline';
      }
      return 'mdi-progress-clock';
    });
    const screenshotUrl = computed<string>(() => {
      const _url = urlWithHttpsProtocol(props.project._projectDomain);
      if (_url) {
        const timestamp = props.project.productionBranch?.deploymentState?.lastDeploymentDate
          ? Math.round(new Date(props.project.productionBranch?.deploymentState?.lastDeploymentDate).getTime() / 1000)
          : undefined;
        if (timestamp) {
          return `${screenshotApiUrl}?url=${encodeURIComponent(_url)}&timestamp=${timestamp}`;
        }
      }
      return '';
    });

    return {
      placeholderIcon,
      showPlaceholder,
      loading,
      screenshotUrl,
    };
  },
});
