


































































import { computed, defineComponent, PropType } from '@vue/composition-api';
import { Deployment, Project, ProjectType } from '@/model/store';
import AppProjectSettingsMenuItem from '@/components/project/shared/SettingsMenuItem.vue';
import {
  isPhpProject as _isPhpProject,
  isAMultiDeploymentProject as _isAMultiDeploymentProject,
  isStarterProjectByType,
} from '@/utils/projects';

export default defineComponent({
  name: 'AppProjectSettingsMenu',
  components: { AppProjectSettingsMenuItem },
  props: {
    project: {
      type: Object as PropType<Project>,
      required: true,
    },
  },
  inject: [
    'openChangeProjectTypeDialog',
    'openChangePhpVersionDialog',
    'openChangePublicFolderPathDialog',
    'openChangeVisitorStatisticsDialog',
    'openChangeNameDialog',
    'openConnectDomainDialog',
    'openChangeAutomaticDeploymentDialog',
    'openDeleteDialog',
  ],
  setup(props) {
    const hasBranches = computed<boolean>(() => {
      return props.project.branches.some((data) => data.deploymentCount > 0);
    });

    const isPhpProject = computed<boolean>(() => {
      return _isPhpProject(props.project);
    });

    const isAMultiDeploymentProject = computed<boolean>(() => {
      return _isAMultiDeploymentProject(props.project);
    });

    const productionDeployment = computed<Deployment | undefined>(() => {
      return props.project.productionBranch?.deployments[0];
    });

    return {
      hasBranches,
      isPhpProject,
      isAMultiDeploymentProject,
      isStarterProjectByType,
      productionDeployment,
      ProjectType,
    };
  },
});
