


























































































import { defineComponent, ref, computed, provide, onUnmounted } from '@vue/composition-api';
import AppDeleteDialog from '@/components/project/DeleteDialog.vue';
import AppChangeNameDialog from '@/components/project/ChangeNameDialog.vue';
import AppWorkflowConfigurationDialog from '@/components/project/WorkflowConfigurationDialog.vue';
import AppChangeVisitorStatisticsDialog from '@/components/project/ChangeVisitorStatisticsDialog.vue';
import AppAddDeploymentDialog from '@/components/project/AddDeploymentDialog.vue';
import AppChangePhpVersionDialog from '@/components/project/ChangePhpVersionDialog.vue';
import AppChangePublicFolderPath from '@/components/project/ChangePublicFolderPath.vue';
import AppChangeProjectTypeDialog from '@/components/project/ChangeProjectTypeDialog.vue';
import AppChangeAutomaticDeploymentDialog from '@/components/project/ChangeAutomaticDeploymentDialog.vue';
import AppNotificationStatusPanel from '@/components/shared/NotificationStatusPanel.vue';
import AppGithubInstallationStatusPanel from '@/components/shared/GithubInstallationStatusPanel.vue';
import AppLoadingSpinner from '@/components/shared/LoadingSpinner.vue';
import AppConnectDomainDialog from '@/components/project/domain/ConnectDomainDialog.vue';
import AppProjectInfoSection from '@/components/project/InfoSection.vue';
import AppProjectDeploymentsSection from '@/components/project/DeploymentsSection.vue';
import { useProjectsStore } from '@/stores/projects';
import { storeToRefs } from 'pinia';
import { useGitHubStore } from '@/stores/github';
import { isPhpProject as _isPhpProject } from '@/utils/projects';

export default defineComponent({
  name: 'ProjectDetails',
  components: {
    AppDeleteDialog,
    AppChangeNameDialog,
    AppWorkflowConfigurationDialog,
    AppChangeVisitorStatisticsDialog,
    AppAddDeploymentDialog,
    AppChangePhpVersionDialog,
    AppChangePublicFolderPath,
    AppChangeProjectTypeDialog,
    AppChangeAutomaticDeploymentDialog,
    AppNotificationStatusPanel,
    AppGithubInstallationStatusPanel,
    AppLoadingSpinner,
    AppConnectDomainDialog,
    AppProjectInfoSection,
    AppProjectDeploymentsSection,
  },
  setup(props, { root }) {
    const projectsStore = useProjectsStore();
    const githubStore = useGitHubStore();

    const loading = ref<boolean>(true);

    const { selectedProject } = storeToRefs(projectsStore);
    const project = selectedProject;
    const { getInstallationUrl, isFullyConnected } = storeToRefs(githubStore);
    const restorePermissionUrl = getInstallationUrl;
    const githubFullyConnected = isFullyConnected;

    const productionBranchDeleted = computed<boolean>(() => {
      return project.value ? project.value.productionBranch.deleted : false;
    });
    const permissionLost = computed<boolean>(() => {
      return project.value ? project.value.permissionLost : false;
    });
    const projectDeleted = computed<boolean>(() => {
      return project.value ? project.value.deleted : false;
    });
    const isPhpProject = computed<boolean>(() => {
      return project.value ? _isPhpProject(project.value) : false;
    });

    onUnmounted(() => {
      if (project.value?._freshlyCreated) {
        const _project = project.value;
        _project._freshlyCreated = false;
        projectsStore.replaceProject(project.value.id, _project);
      }
    });

    const dialogNames = [
      'ChangeAutomaticDeployment',
      'ConnectDomain',
      'ChangeProjectType',
      'ChangePhpVersion',
      'ChangePublicFolderPath',
      'ChangeVisitorStatistics',
      'WorkflowConfiguration',
      'AddDeployment',
      'Delete',
      'ChangeName',
    ];

    const dialogsRefs = {};

    dialogNames.forEach((value) => {
      dialogsRefs[value + 'DialogRef'] = ref();
    });

    dialogNames.forEach((value) => {
      provide('open' + value + 'Dialog', (...args) => {
        dialogsRefs[value + 'DialogRef'].value.open(...args);
      });
    });

    projectsStore.selectProject(root.$route.params.projectId).then(() => {
      projectsStore.loadDeploymentDetailsForAllDeploymentsOfProject(root.$route.params.projectId);
      loading.value = false;
    });

    return {
      loading,
      ...dialogsRefs,
      project,
      restorePermissionUrl,
      githubFullyConnected,
      productionBranchDeleted,
      isPhpProject,
      permissionLost,
      projectDeleted,
    };
  },
});
