
































import { computed, defineComponent, ref } from '@vue/composition-api';
import { Domain } from 'ionos-space-api-v4';
import { ACCOUNT_ME } from '@/utils/const';
import { accountApiClient } from '@/plugins/axios';

interface DomainItem extends Domain {
  baseDomain: string;
  text: string;
  value: string;
}

export default defineComponent({
  name: 'AppDomainSelect',
  props: {
    value: String,
    currentDomain: String,
    additionalDomains: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      required: false,
    },
  },
  setup(props, { emit }) {
    const domains = ref([] as any);
    const loading = ref(false);
    const loaded = ref(false);

    const domainNames = computed<DomainItem[]>(() => {
      let sortedDomains = domains.value;
      sortedDomains = sortedDomains.map((domain: Domain): DomainItem => {
        let baseDomain = domain.name;
        if (domain.type === 'SUBDOMAIN') {
          let domainsParts = domain.name.split('.');
          baseDomain = domainsParts[domainsParts.length - 2] + '.' + domainsParts[domainsParts.length - 1];
        }
        return Object.assign(domain, {
          baseDomain: baseDomain,
          text: domain.name,
          value: domain.name,
          disabled: props.currentDomain === domain.name || domain.inUse,
        });
      });
      sortedDomains.sort(function (a: Domain, b: Domain): number {
        return a.name < b.name ? -1 : 1;
      });
      sortedDomains.sort(function (a: DomainItem, b: DomainItem): number {
        if (a.baseDomain === b.baseDomain) {
          return 0;
        }
        return a.baseDomain < b.baseDomain ? -1 : 1;
      });
      sortedDomains.sort(function (a: DomainItem, b: DomainItem): number {
        if (a.baseDomain !== b.baseDomain) {
          return 0;
        }
        return a.type === 'MAIN' ? -1 : 1;
      });
      return sortedDomains;
    });

    async function loadDomains() {
      if (!loaded.value) {
        loading.value = true;
        domains.value = [...props.additionalDomains];
        const domainsFromApi = await accountApiClient
          .getDomains(ACCOUNT_ME)
          .then(({ data }) => data)
          .catch((error) => console.log(error));
        if (domainsFromApi) {
          domains.value = [...domains.value, ...domainsFromApi];
        }
        loading.value = false;
      }
    }

    function onChange(domainName) {
      emit('input', domainName);
    }

    return {
      loading,
      domainNames,
      loadDomains,
      onChange,
    };
  },
});
