













import { defineComponent, PropType, ref, watch } from '@vue/composition-api';
import { Project } from '@/model/store';
import { ACCOUNT_ME } from '@/utils/const';
import { projectApiClient } from '@/plugins/axios';
import { useI18n } from 'vue-i18n-bridge';

export default defineComponent({
  name: 'AppDeploymentNameInput',
  props: {
    project: {
      type: Object as PropType<Project>,
      required: true,
    },
    proposal: String,
    dontCheckProposalViaApi: Boolean,
    label: String,
  },
  emits: ['input'],
  setup(props, { emit }) {
    const loading = ref<boolean>(false);
    const { t } = useI18n();

    const internalName = ref<string>(props.proposal ?? '');
    const handset = ref<boolean>(false);
    const nameIsValid = ref<boolean>(false);

    const errorMessage = ref<string>(undefined as any);

    let apiCheckTimeout;

    const input = () => {
      handset.value = !!internalName.value;
      checkDeploymentName();
    };

    const checkDeploymentName = () => {
      invalidateDeploymentName();
      if (apiCheckTimeout) {
        clearTimeout(apiCheckTimeout);
        loading.value = false;
      }
      if (internalName.value) {
        if (internalName.value === props.proposal && props.dontCheckProposalViaApi) {
          nameIsValid.value = true;
          emit('input', internalName.value);
          return;
        }

        loading.value = true;
        const _internalName = internalName.value;
        apiCheckTimeout = setTimeout(() => {
          projectApiClient
            .validateDeploymentName(ACCOUNT_ME, props.project.id, {
              deploymentName: _internalName,
            })
            .then(() => {
              nameIsValid.value = true;
              emit('input', internalName.value);
            })
            .catch(() => {
              errorMessage.value = t(
                'projectDetails.changeNameDialog.ChangeDeploymentNameMode.formErrors.nameAlreadyTaken'
              );
            })
            .finally(() => {
              loading.value = false;
            });
        }, 500);
      } else {
        handset.value = false;
      }
    };

    const invalidateDeploymentName = () => {
      emit('input', '');
      nameIsValid.value = false;
      errorMessage.value = '';
    };

    watch(
      () => props.proposal,
      (newVal) => {
        if (!handset.value && newVal) {
          internalName.value = newVal;
          checkDeploymentName();
        }
      }
    );

    return {
      loading,
      internalName,
      nameIsValid,
      errorMessage,
      input,
    };
  },
});
