

































import { defineComponent, PropType, ref } from '@vue/composition-api';
import { useI18n } from 'vue-i18n-bridge';
import { Project } from '@/model/store';
import { isPhpProject } from '@/utils/projects';

export default defineComponent({
  name: 'AppWorkflowConfigurationDialog',
  props: {
    project: {
      type: Object as PropType<Project>,
      required: true,
    },
  },
  setup(props) {
    const { tm } = useI18n();
    const branch = ref('');
    const sections = ref<
      Array<{
        title: string;
        links: Array<{ text: string; url: string; description?: string; type?: string }>;
      }>
    >();

    const dialog = {
      dialogRef: ref(null as any),
      open: (branchName: string) => {
        branch.value = branchName;
        sections.value = createSections();
        dialog.dialogRef.value.open();
      },
      close: () => {
        dialog.dialogRef.value.close();
      },
    };

    const createSections = () => {
      const sections = tm('projectDetails.workflowConfigurationDialog.sections') as Array<{
        title: string;
        links: Array<{ text: string; url: string; description?: string; type?: string }>;
      }>;

      if (!isPhpProject(props.project)) {
        return [sections[0]];
      }

      return sections;
    };

    function replacePlaceholder(text: string): string {
      return text
        .replace('{repo}', props.project.gitRepository.fullName)
        .replace('{branch}', branch.value)
        .replace('{project}', props.project.name);
    }

    return {
      ...dialog,
      sections,
      replacePlaceholder,
    };
  },
});
