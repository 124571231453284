












import { defineComponent, PropType } from '@vue/composition-api';
import { ProjectType } from '@/model/store';
import { useAccountInfoStore } from '@/stores/account-info';

export default defineComponent({
  name: 'AppProjectTypeHeader',
  props: {
    projectType: {
      type: Object as PropType<ProjectType>,
      required: true,
    },
  },
  setup() {
    const accountInfoStore = useAccountInfoStore();

    return {
      accountInfoStore,
    };
  },
});
