


























import { defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  name: 'AppDomainConflict',
  props: {
    conflict: {
      type: Object as PropType<{ conflictingRecords: { type: string; content: string; name: string }[] }>,
      required: true,
    },
  },
});
