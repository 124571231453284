






















import { computed, defineComponent, PropType } from '@vue/composition-api';
import { Project } from '@/model/store';

interface BranchItem {
  value: string;
  text: string;
  disabled: boolean;
}

export default defineComponent({
  name: 'AppDeploymentBranchSelect',
  props: {
    project: {
      type: Object as PropType<Project>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const branches = computed<BranchItem[]>(() => {
      const branches = props.project.branches.map((branch) => {
        return {
          value: branch.id,
          text: branch.name,
          disabled: branch.deploymentCount > 0,
        };
      });
      return branches.sort((a, b) => {
        return a.text < b.text ? -1 : 1;
      });
    });

    function onChange(branch) {
      emit('input', branch);
    }

    return {
      branches,
      onChange,
    };
  },
});
