






















import { defineComponent, computed, PropType } from '@vue/composition-api';
import { Branch, BranchState } from '@/model/store';
import AppTimeAgo from '@/components/shared/TimeAgo.vue';
import { useI18n } from 'vue-i18n-bridge';

export default defineComponent({
  name: 'AppBranchBuildState',
  components: { AppTimeAgo },
  props: {
    branch: {
      type: Object as PropType<Branch>,
      required: true,
    },
    detailed: Boolean,
    small: Boolean,
  },
  setup(props) {
    const { t } = useI18n();

    const showLastBuildDate = computed<boolean>(() => {
      switch (props.branch!.buildState?.state) {
        case BranchState.AWAITING_BUILD:
        case BranchState.INITIAL_BUILD_FAILED:
        case BranchState.SUCCESS:
        case BranchState.RUNNING:
        case BranchState.FAILED:
          return true;
        default:
          return false;
      }
    });
    const label = computed<string>(() => {
      return t(`application.project.branch.buildState.${props.branch!.buildState?.state}`).toString();
    });
    const color = computed<string>(() => {
      switch (props.branch!.buildState?.state) {
        case BranchState.AWAITING_BUILD:
        case BranchState.RUNNING:
          return 'info';
        case BranchState.FAILED:
        case BranchState.INITIAL_BUILD_FAILED:
        case BranchState.ON_GITHUB_DELETE:
        case BranchState.WORKFLOW_MISSING:
          return 'error';
        case BranchState.SUCCESS:
          return 'success';
        case BranchState.UNKNOWN:
          return 'primary';
        default:
          return '';
      }
    });
    const icon = computed<string>(() => {
      switch (props.branch!.buildState?.state) {
        case BranchState.AWAITING_BUILD:
          return 'mdi-timer-sand-empty';
        case BranchState.INITIAL_BUILD_FAILED:
        case BranchState.FAILED:
        case BranchState.ON_GITHUB_DELETE:
        case BranchState.WORKFLOW_MISSING:
          return 'mdi-alert-circle-outline';
        case BranchState.SUCCESS:
          return 'mdi-check-circle-outline';
        case BranchState.RUNNING:
          return 'mdi-autorenew';
        case BranchState.UNKNOWN:
          return 'mdi-help-circle-outline';
        default:
          return '';
      }
    });
    const lastBuildDate = computed<Date | undefined>(() => {
      return props.branch!.buildState?.lastBuildDate;
    });

    return {
      showLastBuildDate,
      icon,
      color,
      lastBuildDate,
      label,
    };
  },
});
