









































import { Project } from '@/model/store';
import { ProjectType } from 'ionos-space-api-v4';
import { computed, defineComponent, PropType, ref } from '@vue/composition-api';
import { useMasterDataStore } from '@/stores/master-data';
import AppProjectDeploymentsTableHeader from '@/components/project/deployment/DeploymentsTableHeader.vue';
import AppProjectDeploymentsTableData from '@/components/project/deployment/DeploymentsTableData.vue';

export default defineComponent({
  name: 'AppProjectDeploymentsSection',
  components: { AppProjectDeploymentsTableData, AppProjectDeploymentsTableHeader },
  props: {
    project: {
      type: Object as PropType<Project>,
      required: true,
    },
  },
  inject: ['openAddDeploymentDialog'],
  setup(props) {
    const masterDataStore = useMasterDataStore();

    const startSaveBranchDeploymentStates = ref<boolean>(false);

    const projectTypeByName = computed(() => masterDataStore.projectTypeByName);
    const projectType = computed<ProjectType | undefined>(() => {
      return masterDataStore.projectTypeByName(props.project.projectType || '');
    });

    return {
      startSaveBranchDeploymentStates,
      projectTypeByName,
      projectType,
    };
  },
});
