








































import { defineComponent, computed, PropType } from '@vue/composition-api';
import AppScreenshotImage from '@/components/shared/ScreenshotImage.vue';
import AppProjectSettingsMenu from '@/components/project/projectSettings/Menu.vue';
import { Branch, Project } from '@/model/store';
import { storeToRefs } from 'pinia';
import { useProjectsStore } from '@/stores/projects';
import { urlWithHttpsProtocol } from '@/utils';

export default defineComponent({
  name: 'AppProjectInfoSection',
  components: {
    AppScreenshotImage,
    AppProjectSettingsMenu,
  },
  props: {
    project: {
      type: Object as PropType<Project>,
      required: true,
    },
  },
  setup(props) {
    const { projectGithubUrl } = storeToRefs(useProjectsStore());

    const name = computed<string>(() => {
      return props.project.name;
    });
    const domainAsUrl = computed<string | undefined>(() => {
      return urlWithHttpsProtocol(props.project._projectDomain);
    });
    const productionBranch = computed<Branch | undefined>(() => {
      return props.project.productionBranch;
    });

    return {
      projectGithubUrl,
      name,
      domainAsUrl,
      productionBranch,
    };
  },
});
