





















import { defineComponent, PropType } from '@vue/composition-api';
import { prettyBytes } from '@/utils';
import { isPhpProjectByType } from '@/utils/projects';
import { ProjectType } from '@/model/store';

export default defineComponent({
  name: 'AppProjectTypeFeatures',
  props: {
    projectType: {
      type: Object as PropType<ProjectType>,
      required: true,
    },
  },
  setup() {
    return {
      prettyBytes,
      isPhpProjectByType,
    };
  },
});
