









































































import { Project } from '@/model/store';
import { useMasterDataStore } from '@/stores/master-data';
import { computed, defineComponent, PropType } from '@vue/composition-api';
import { ProjectType } from 'ionos-space-api-v4';
import AppBranchBuildState from '@/components/shared/BranchBuildState.vue';
import AppBranchBuildBranch from '@/components/shared/BranchBuildBranch.vue';
import AppDeploymentsSettingsMenu from '@/components/project/deployment/Menu.vue';

export default defineComponent({
  name: 'AppProjectDeploymentsTableHeader',
  props: {
    forProductionDeployments: Boolean,
    project: Object as PropType<Project>,
    usedDeployments: {
      type: Number,
      required: true,
    },
    maximumDeployments: {
      type: Number,
      default: 0,
    },
  },
  components: {
    AppBranchBuildBranch,
    AppBranchBuildState,
    AppDeploymentsSettingsMenu,
  },
  inject: ['openAddDeploymentDialog'],
  setup(props) {
    const masterDataStore = useMasterDataStore();

    const projectType = computed<ProjectType | undefined>(() => {
      return masterDataStore.projectTypeByName(props.project?.projectType || '');
    });

    return {
      projectType,
    };
  },
});
