var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[(_vm.forProductionDeployments)?_c('div',[_c('v-col',{attrs:{"cols":"auto"}},[_c('div',[_c('h3',{staticClass:"display-3"},[_vm._v(" "+_vm._s(_vm.$tc('projectDetails.deployments.production.headline', _vm.usedDeployments))+" ")]),_c('h4',{staticClass:"title text--secondary mb-8"},[_vm._v(" "+_vm._s(_vm.$tc('projectDetails.deployments.production.subHeadline', _vm.usedDeployments, { use: _vm.usedDeployments, projectType: _vm.$t(("application.projectTypeLabels." + (_vm.projectType.name))), }))+" ")])]),(_vm.project.productionDeploymentCount > 1)?_c('div',{staticClass:"d-flex pb-8"},[_c('app-branch-build-state',{attrs:{"branch":_vm.project.productionBranch,"small":"","detailed":""}}),_c('app-branch-build-branch',{staticClass:"ml-2",attrs:{"branch":_vm.project.productionBranch}})],1):_vm._e()])],1):_c('div',[_c('v-col',{attrs:{"cols":"auto"}},[_c('div',[_c('h3',{staticClass:"display-3"},[_vm._v(_vm._s(_vm.$tc('projectDetails.deployments.staging.headline', _vm.usedDeployments)))]),_c('h4',{staticClass:"title text--secondary mb-8",domProps:{"innerHTML":_vm._s(
              _vm.$t('projectDetails.deployments.staging.subHeadline', {
                used: _vm.usedDeployments,
                available: _vm.maximumDeployments,
              })
            )}})])])],1),_c('v-col',{attrs:{"align":"right"}},[(
          (_vm.forProductionDeployments && _vm.project.spawningEnabled && _vm.project.productionDeploymentCount > 1) ||
          !_vm.forProductionDeployments
        )?_c('ionos-button',{staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){return _vm.openAddDeploymentDialog(_vm.forProductionDeployments)}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e(),(_vm.usedDeployments > 0)?_c('app-deployments-settings-menu',{attrs:{"for-production-deployments":_vm.forProductionDeployments,"project":_vm.project}}):_vm._e()],1)],1),(_vm.usedDeployments > 0)?_c('v-row',{staticClass:"display-1"},[_c('v-col',{attrs:{"cols":!_vm.forProductionDeployments || _vm.project.productionDeploymentCount === 1 ? 2 : 3}},[_vm._v(" "+_vm._s(_vm.$t('projectDetails.branches.table.name'))+" ")]),(!_vm.forProductionDeployments || _vm.project.productionDeploymentCount === 1)?_c('v-col',{attrs:{"cols":"4"}},[_vm._v(_vm._s(_vm.$t('projectDetails.branches.table.build'))+" ")]):_vm._e(),_c('v-col',[_vm._v(_vm._s(_vm.$t('projectDetails.branches.table.deployment'))+" ")]),_c('v-col',{attrs:{"cols":"1"}},[_vm._v(_vm._s(_vm.$t('projectDetails.branches.table.storage'))+" ")]),_c('v-col',{attrs:{"cols":"1"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }