








































import { defineComponent, ref, computed, PropType } from '@vue/composition-api';
import { Deployment, Project } from '@/model/store';
import { deploymentNamePatch, patchDeployment, patchProject, projectNamePatch } from '@/services/patch-service';
import AppDeploymentNameInput from '@/components/project/shared/DeploymentNameInput.vue';

enum Mode {
  ChangeDeploymentNameMode = 'ChangeDeploymentNameMode',
  ChangeProjectNameMode = 'ChangeProjectNameMode',
}

export default defineComponent({
  name: 'AppChangeNameDialog',
  components: { AppDeploymentNameInput },
  props: {
    project: {
      type: Object as PropType<Project>,
      required: true,
    },
  },
  setup(props) {
    const loading = ref<boolean>(false);

    const mode = ref<Mode>(Mode.ChangeProjectNameMode);

    const newName = ref<string>('');
    const oldName = ref<string>('');

    const deployment = ref<Deployment>(undefined as any);
    const deploymentDomain = computed(() => {
      return deployment.value?.domain?.name;
    });

    const dialog = {
      dialogRef: ref(null as any),
      open: (_deployment?: Deployment) => {
        if (_deployment) {
          mode.value = Mode.ChangeDeploymentNameMode;
          deployment.value = _deployment;
          newName.value = oldName.value = _deployment.name;
        } else {
          mode.value = Mode.ChangeProjectNameMode;
          deployment.value = undefined as any;
          newName.value = oldName.value = props.project.name;
        }
        dialog.dialogRef.value.open();
      },
      close: () => {
        dialog.dialogRef.value.close();
      },
      formValid: computed<boolean>(() => {
        return !!(newName.value && newName.value !== oldName.value);
      }),
      save: () => {
        loading.value = true;
        let call: Promise<any> | undefined;
        if (mode.value === Mode.ChangeProjectNameMode) {
          call = patchProject(props.project.id, projectNamePatch(newName.value));
        } else {
          call = patchDeployment(
            deployment.value._projectId,
            deployment.value._branchId,
            deployment.value.id,
            deploymentNamePatch(newName.value)
          );
        }
        call
          .then(() => {
            dialog.close();
          })
          .finally(() => {
            loading.value = false;
          });
      },
    };

    return {
      ...dialog,
      mode,
      Mode,
      loading,
      deployment,
      newName,
      oldName,
      deploymentDomain,
    };
  },
});
